import { Injectable } from '@angular/core';
import { NativeZoomWMS } from 'app/helpers/MaxNativeWMS';
import { GeometryProjectUser } from 'app/models/projectUser';
import { TileConfig } from 'app/shared/base-tiles/base-custom/base-custom.component';
import { BehaviorSubject, Observable } from 'rxjs';

interface MessageGeometry {
    action: 'create' | 'update' | 'delete';
    geojson: GeometryProjectUser;
}
@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private message = new BehaviorSubject(null);
    message$: Observable<any> = this.message.asObservable();

    private messageCloselayer = new BehaviorSubject(null);
    messageCloseCamada$: Observable<any> =
        this.messageCloselayer.asObservable();

    private messageGeometry = new BehaviorSubject(null);
    messageGeometry$: Observable<MessageGeometry> =
        this.messageGeometry.asObservable();

    private messageTileChange = new BehaviorSubject(null);
    messageTileChange$: Observable<TileConfig | NativeZoomWMS> =
        this.messageTileChange.asObservable();

    private messageTiles = new BehaviorSubject(null);
    messageTiles$: Observable<{ [key: string]: TileConfig }> =
        this.messageTiles.asObservable();

    //Desabilita selecao de Tiles
    private messageDisabledLayerBase = new BehaviorSubject(null);
    messageDisabledLayerBase$: Observable<boolean> =
        this.messageDisabledLayerBase.asObservable();

    private messageMainNavigation = new BehaviorSubject(null);
    messageMainNavigation$: Observable<any> =
        this.messageMainNavigation.asObservable();

    closePopup(): void {
        this.message.next('close popup');
    }

    closeLayer(): void {
        this.messageCloselayer.next('close layer');
    }

    deleteGeometry(geo: GeometryProjectUser): void {
        this.messageGeometry.next({ action: 'delete', geojson: geo });
    }

    UpdateGeometry(geo: GeometryProjectUser): void {
        this.messageGeometry.next({ action: 'update', geojson: geo });
    }

    createGeometry(geo: GeometryProjectUser): void {
        this.messageGeometry.next({ action: 'create', geojson: geo });
    }

    tileChange(tile): void {
        this.messageTileChange.next(tile);
    }

    tiles(tiles: { [key: string]: TileConfig }): void {
        this.messageTiles.next(tiles);
    }

    disabledLayerBase(op: boolean): void {
        this.messageDisabledLayerBase.next(op);
    }

    closeMainNavigation(opened: boolean) {
        this.messageMainNavigation.next(opened);
    }
}
